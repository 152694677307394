import { memo, useCallback, useMemo } from 'react'
import { sortBy } from 'lodash'

import { Badge, Box, CheckboxGroup, FilterAccordionPanel, Pill } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { useRunbookComponentFilter } from 'main/recoil/data-access'
import { RunbookComponentModel, TaskModel } from 'main/data-access'
import { RunbookComponent } from 'main/services/queries/types'

const MAX_BADGE_COUNT = 9999
export const SourceGroup = memo(() => {
  const { t } = useLanguage('common', { keyPrefix: 'filter.labels' })
  const rbcs = RunbookComponentModel.useGetAll()
  const [rbcFilter, setRbcFilter] = useRunbookComponentFilter()

  const handleChange = useCallback(
    (event: any) => event && setRbcFilter(event.value as unknown as number[]),
    [setRbcFilter]
  )

  const sortedRbcs = useMemo(() => sortBy(rbcs, rbc => rbc.name.toLowerCase()), [rbcs])

  return (
    <FilterAccordionPanel initialActive label={t('source')} applied={!!rbcFilter?.length}>
      <CheckboxGroup
        plain
        value={rbcFilter || []}
        a11yTitle={t('source')}
        onChange={handleChange}
        options={sortedRbcs.map(rbc => ({
          value: rbc.internal_id,
          label: rbc.name,
          key: rbc.id,
          suffix: rbc.applied_to_internal_task_id ? (
            <FilterOptionSuffixWithTaskReference key={rbc.id} runbookComponent={rbc} />
          ) : (
            <Badge
              max={MAX_BADGE_COUNT}
              data-testid={`${rbc.name}-task-count`}
              key={rbc.id}
              type="light"
              label={rbc.tasks_count}
            />
          )
        }))}
      />
    </FilterAccordionPanel>
  )
})

const FilterOptionSuffixWithTaskReference = ({ runbookComponent }: { runbookComponent: RunbookComponent }) => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const task = TaskModel.useGetBy({ internal_id: runbookComponent.applied_to_internal_task_id as number })
  return task ? (
    <Box direction="row" gap="xxsmall" align="center">
      <Pill
        data-testid={`${runbookComponent.name}-task-id`}
        truncate
        tip={task.name}
        color="badge-dark-bg"
        label={t('appliedToTask', { taskId: runbookComponent.applied_to_internal_task_id })}
      />
      <Badge
        type="light"
        max={MAX_BADGE_COUNT}
        data-testid={`${runbookComponent.name}-task-count`}
        label={runbookComponent.tasks_count}
      />
    </Box>
  ) : null
}
