import { useRef, useState } from 'react'
import { ClickEvent } from '@szhsin/react-menu'

import { toCamelCase } from '@cutover/api'
import { Box, Menu } from '@cutover/react-ui'
import { usePostAppEvents } from '../apps-api'
import { AppsComponentParser } from '../apps-component-parser'
import { useComponentPropsAndState } from '../apps-state'
import { AppComponentNodeProps, ContentNode, ContentNodeOption } from '../apps-types'

type MenuNodeProps = AppComponentNodeProps & {
  content: ContentNode[]
  options: ContentNodeOption[]
  defaultOpen?: boolean
}

export const MenuNode = ({ appId, resourceId, id, defaultOpen = false, ...props }: MenuNodeProps) => {
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(defaultOpen)
  const postAppEvents = usePostAppEvents()
  const { componentProps } = useComponentPropsAndState(appId, resourceId, id, props)
  const { options, content } = componentProps as MenuNodeProps

  const openInNewTab = (href: string) => {
    window.open(href, '_blank', 'noreferrer')
  }

  const handleOptionClick = (option: ContentNodeOption) => {
    if (option.href) {
      openInNewTab(option.href)
    }

    const payload = {
      app_id: appId,
      runbook_id: resourceId,
      events: [option]
    }
    postAppEvents(payload)
  }

  const items = toCamelCase(options).map(option => {
    const { type: _, ...optionWithoutType } = option as ContentNodeOption
    return {
      ...optionWithoutType,
      onClick: (e: ClickEvent) => {
        e.syntheticEvent.stopPropagation()
        handleOptionClick(option)
      }
    }
  })

  return (
    <>
      <Box
        flex={false}
        data-testid="menu-node-box"
        ref={ref}
        onClick={e => {
          e.stopPropagation()
          setOpen(!isOpen)
        }}
        css={`
          min-width: auto;
          min-height: auto;
        `}
      >
        <AppsComponentParser content={content || []} appId={appId} resourceId={resourceId} />
      </Box>
      {!!items.length && (
        <Menu
          menuStyle={{ maxHeight: '288px', overflow: 'auto' }}
          triggerRef={ref}
          isOpen={isOpen}
          items={items}
          onClose={e => e?.reason === 'blur' && setOpen(false)}
        />
      )}
    </>
  )
}
