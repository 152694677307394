import { useEffect } from 'react'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

import { RunbookViewModel } from 'main/data-access'
import { apiClient_UNSTABLE } from '..'

const RUNBOOK_VERSION_REGEX = /runbooks\/\d+\/runbook_versions/

type ErrorResponse = {
  redirect_url?: string
}

// Runbook-level interceptor for all axios requests, needs to be called in the runbook context only
// TODO more errors need to be handled here, see promise_helper_factory.js for the full list
// https://cutover.atlassian.net/browse/CFE-2372
export const useRunbookAPIInterceptor = () => {
  const openModal = RunbookViewModel.useAction('modal:open')
  const navigate = useNavigate()

  useEffect(() => {
    const responseIntercepter = apiClient_UNSTABLE.interceptors.response.use(
      response => response,
      function (error: AxiosError<ErrorResponse>) {
        if (!error.response || !error.response.status) return Promise.reject(error)
        const { data, config } = error.response

        switch (error.response.status) {
          case 403:
            if (data?.redirect_url) {
              // TODO: We may need to remove this replace after AngularJS is completely removed
              navigate(data.redirect_url.replace('/#', ''))
            } else if (config?.url && RUNBOOK_VERSION_REGEX.test(config.url)) {
              openModal({ type: 'task-unable-to-perform-action' })
            }
            break
          default:
            break
        }

        return Promise.reject(error)
      }
    )

    return () => {
      apiClient_UNSTABLE.interceptors.response.eject(responseIntercepter)
    }
  }, [])
}
