import { Ref, useCallback } from 'react'
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil'
import { produce } from 'immer'
import { extend, isEmpty, pullAllWith } from 'lodash'

import {
  DependencyType,
  MenuListItemProps,
  NotifyOptions,
  RequiredTitleNotifyOptions,
  TaskItemIcon,
  useNotify
} from '@cutover/react-ui'
import {
  accountTaskTypeLookup,
  filteredTaskListIdsState,
  IntegrationRequest,
  isVersionEditable,
  ModalActiveType,
  newTaskStreamState,
  runbookPermission,
  runbookState,
  runbookVersionStageState,
  runbookVersionState,
  runbookViewStateCopyIds_INTERNAL,
  runbookViewStateHighlightMode_INTERNAL,
  runbookViewStateIntegrationRequest_INTERNAL,
  runbookViewStateLoadingIds_INTERNAL,
  runbookViewStateMenu_INTERNAL,
  runbookViewStateModal_INTERNAL,
  runbookViewStateNewCommentsCount_INTERNAL,
  runbookViewStateNodeMap_INTERNAL,
  runbookViewStateNotifications_INTERNAL,
  runbookViewStateSelectedEdges_INTERNAL,
  runbookViewStateSelectedIds_INTERNAL,
  runbookViewStateSelectedTimezone_INTERNAL,
  runbookViewStateSort_INTERNAL,
  runbookViewStateTaskCreate_INTERNAL,
  streamsLookupState,
  streamsPermittedState,
  taskListLookupState,
  TaskListMenu
} from 'main/recoil/runbook'
import { useLanguage } from 'main/services/hooks'
import {
  INTEGRATION_FINISHED_STATUSES,
  INTEGRATION_RUNNING_STATUSES,
  IntegrationActionItem,
  IntegrationFinishedStatus,
  IntegrationRunningStatus,
  TaskListTask
} from 'main/services/queries/types'
import { fireIntegration, resumePolling } from 'main/services/queries/use-task'
import { useToggleRightPanel } from 'main/components/layout/right-panel'
import { stageIconName, taskTypeIcon } from 'main/components/runbook/pages/task-list/task-item/task-list-item-props'
import { filterSelector } from 'main/recoil/shared/filters'
import { useActiveRunbookCan } from './active-runbook'
import { useActionTaskPermission, useCanTask, useCreateTaskPermission, useDeleteTaskPermission } from './task'
import { RunbookViewModel } from 'main/data-access/view-models'
import { useGetRunbookVersion, useGetRunbookVersionCallback } from './active-runbook-version'
import { useGetDashboardBy, useGetDashboardByCallback } from './dashboard'
import { useGetRun, useGetRunCallback } from './active-run'
import { Edge } from 'main/services/api/data-providers/runbook-types'

/* -------------------------------------------------------------------------- */
/*                                 Loading Ids                                */
/* -------------------------------------------------------------------------- */
export const useLoadingIdsValue = () => useRecoilValue(runbookViewStateLoadingIds_INTERNAL)
export const useLoadingIdsValueCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(runbookViewStateLoadingIds_INTERNAL),
    []
  )

export const useLoadingIdAdd = () =>
  useRecoilCallback(
    ({ set }) =>
      (id: number) =>
        set(runbookViewStateLoadingIds_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft[id] = true
          })
        ),
    []
  )

export const useLoadingIdRemove = () =>
  useRecoilCallback(
    ({ set }) =>
      (id: number) =>
        set(runbookViewStateLoadingIds_INTERNAL, previousState =>
          produce(previousState, draft => {
            delete draft[id]
          })
        ),
    []
  )

export const useLoadingIdAddBulk = () =>
  useRecoilCallback(
    ({ set }) =>
      (ids: number[]) =>
        set(runbookViewStateLoadingIds_INTERNAL, previousState =>
          produce(previousState, draft => {
            ids.forEach(id => (draft[id] = true))
          })
        ),
    []
  )

export const useLoadingIdRemoveBulk = () =>
  useRecoilCallback(
    ({ set }) =>
      (ids: number[]) =>
        set(runbookViewStateLoadingIds_INTERNAL, previousState =>
          produce(previousState, draft => {
            ids.forEach(id => delete draft[id])
          })
        ),
    []
  )

/* -------------------------------------------------------------------------- */
/*                                    Menu                                    */
/* -------------------------------------------------------------------------- */

export const useMenuValue = () => useRecoilValue(runbookViewStateMenu_INTERNAL)
export const useMenuValueCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        snapshot.getPromise(runbookViewStateMenu_INTERNAL),
    []
  )

export const useMenuToggleTaskAction = () => {
  const buildMenuItems = useBuildTaskActionMenuItems()
  const openMenu = useMenuOpen()
  const closeMenu = useMenuClose()

  return useRecoilCallback(
    ({ snapshot }) =>
      async ({
        task,
        integrationActionItem,
        integrationOptions,
        triggerRef
      }: {
        task: TaskListTask
        integrationActionItem?: IntegrationActionItem
        integrationOptions?: Record<string, object | undefined>
        triggerRef: Ref<HTMLElement>
      }) => {
        const currentMenu = await snapshot.getPromise(runbookViewStateMenu_INTERNAL)

        if (currentMenu.taskId === task.id && currentMenu.type === 'options') {
          return closeMenu()
        }

        const menuItems = await buildMenuItems({ task, integrationActionItem, integrationOptions })
        return openMenu({
          taskId: task.id,
          triggerRef,
          type: 'options',
          keyPrefix: 'task-opts-menu',
          items: menuItems
        })
      },
    [buildMenuItems, closeMenu, openMenu]
  )
}

const MIN_DEPENDENCY_MENU_WIDTH = 200
const MAX_DEPENDENCY_MENU_WIDTH = 320
const MAX_DEPENDENCY_MENU_HEIGHT = 280
export const useMenuToggleTaskDependencies = () => {
  const buildMenuItems = useBuildTaskDependencyMenuItems()
  const openMenu = useMenuOpen()
  const closeMenu = useMenuClose()

  return useRecoilCallback(
    ({ snapshot }) =>
      async ({
        task,
        triggerRef,
        dependencyType
      }: {
        task: TaskListTask
        triggerRef: Ref<HTMLElement>
        dependencyType: DependencyType
      }) => {
        const currentMenu = await snapshot.getPromise(runbookViewStateMenu_INTERNAL)

        if (currentMenu.taskId === task.id && currentMenu.type === dependencyType) {
          return closeMenu()
        }

        const items = await buildMenuItems({ task, type: dependencyType })
        return openMenu({
          taskId: task.id,
          triggerRef,
          type: dependencyType,
          items,
          minWidth: MIN_DEPENDENCY_MENU_WIDTH,
          maxHeight: MAX_DEPENDENCY_MENU_HEIGHT,
          maxWidth: MAX_DEPENDENCY_MENU_WIDTH
        })
      },
    [buildMenuItems, closeMenu, openMenu]
  )
}

export const useMenuOpen = () =>
  useRecoilCallback(
    ({ set }) =>
      (menu: Omit<TaskListMenu, 'open'>) => {
        set(runbookViewStateMenu_INTERNAL, previousState =>
          produce(previousState, draft => {
            extend(draft, { ...menu, open: true })
          })
        )
      },
    []
  )

export const useMenuClose = () =>
  useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(runbookViewStateMenu_INTERNAL)
      },
    []
  )

/* ---------------------------- Menu helpers --------------------------- */

const useBuildTaskActionMenuItems = () => {
  const notify = useRunbookNotifications()
  const { t } = useLanguage('runbook', { keyPrefix: 'taskListItem' })
  const openModal = RunbookViewModel.useAction('modal:open')
  const integrationRequest = useIntegrationRequestValue()
  const setIntegrationRequest = useIntegrationRequestAdd()

  const getCanCreateTask = useCreateTaskPermission()
  const getCanDeleteTask = useDeleteTaskPermission()
  const getCanActionTask = useActionTaskPermission()
  const getCanEditRunbook = useEditRunbookPermissionsCallback()
  const canAddSnippet = useCanTask('add_snippet')

  const copyIdsAdd = useCopyIdsAdd()

  const openTaskCreateForm = useTaskCreateOpenForm()

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async ({
        task,
        integrationActionItem,
        integrationOptions
      }: {
        task: TaskListTask
        integrationActionItem?: IntegrationActionItem
        integrationOptions?: { [x: string]: {} | undefined }
      }) => {
        const { id, name, internal_id: internalId, predecessor_ids: predecessorIds } = task
        const { linked_runbook_details, template_type, ...runbook } = await snapshot.getPromise(runbookState)
        const runbookVersion = await snapshot.getPromise(runbookVersionState)
        const copyIds = await snapshot.getPromise(runbookViewStateCopyIds_INTERNAL)
        const { can: canCopy } = await getCanEditRunbook()

        const canDeleteTask = getCanDeleteTask(id)?.can
        const canMultiplyTask = canDeleteTask // multiply same as update same as delete

        const lastIntegrationEvent =
          task.integration_events.length > 0 && task.integration_events[task.integration_events.length - 1]
        const eventStatus = lastIntegrationEvent && lastIntegrationEvent.status

        const canRefireIntegration =
          INTEGRATION_FINISHED_STATUSES.includes(eventStatus as IntegrationFinishedStatus) &&
          !integrationRequest.hasOwnProperty(task.id)

        const canResumePolling =
          canRefireIntegration &&
          lastIntegrationEvent &&
          (lastIntegrationEvent.error_reason === t('maxPollingReachedError') ||
            lastIntegrationEvent.error_reason === t('pollingTimeoutError') ||
            lastIntegrationEvent.error_reason === t('pollingFailConditionError'))

        const canAbortIntegration =
          INTEGRATION_RUNNING_STATUSES.includes(eventStatus as IntegrationRunningStatus) &&
          integrationOptions?.cancellable &&
          !integrationRequest.hasOwnProperty(task.id)

        const { can: canCreateTaskFromPredecessor } = getCanCreateTask(task.id)
        const canCreateLinkedTask =
          canCreateTaskFromPredecessor &&
          (!linked_runbook_details || isEmpty(linked_runbook_details)) &&
          template_type !== 'snippet'
        const canCreateSnippetTask = canCreateTaskFromPredecessor && template_type !== 'snippet' && canAddSnippet

        const { can: canActionTask } = getCanActionTask(id)

        const menuItems = [
          canMultiplyTask &&
            ({
              label: t('actions.duplicate'),
              a11yTitle: t('actions.duplicate'),
              icon: 'duplicate',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                openModal({
                  taskId: id,
                  taskName: name,
                  type: 'task-duplicate'
                })
              }
            } as MenuListItemProps),
          canCopy &&
            ({
              label: t('actions.copy'),
              a11yTitle: t('actions.copy'),
              icon: 'copy',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                copyIdsAdd([id])
                notify.success(t('actions.pasteClipboard'), {
                  title: t('actions.pasteClipboardTitle')
                })
              }
            } as MenuListItemProps),
          canCopy &&
            ({
              label: t('actions.paste'),
              a11yTitle: t('actions.paste'),
              icon: 'paste',
              appendDivider: true,
              disabled: !copyIds.length,
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                openModal({ type: 'tasks-paste', taskId: id, taskName: name })
              }
            } as MenuListItemProps),
          canActionTask &&
            canAbortIntegration &&
            ({
              label: t('actions.abortIntegration'),
              a11yTitle: t('actions.abortIntegration'),
              icon: 'cancel',
              appendDivider: true,
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()

                openModal({
                  taskId: task.id,
                  name: integrationActionItem?.name ?? '',
                  type: 'integration-abort'
                })
              }
            } as MenuListItemProps),
          canActionTask &&
            canRefireIntegration &&
            ({
              label: t('actions.refireIntegration'),
              a11yTitle: t('actions.refireIntegration'),
              icon: 'refresh',
              disabled: runbookVersion.stage !== 'active',
              appendDivider: true,
              onClick: async e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                setIntegrationRequest({ taskId: id, type: 'refire' })
                await fireIntegration({ runbookId: runbook.id, runbookVersionId: runbookVersion.id, taskId: id })
              }
            } as MenuListItemProps),
          canActionTask &&
            canResumePolling &&
            ({
              label: t('actions.resumePolling'),
              a11yTitle: t('actions.resumePolling'),
              icon: 'play',
              appendDivider: true,
              onClick: async e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                setIntegrationRequest({ taskId: id, type: 'resumePolling' })
                await resumePolling({ runbookId: runbook.id, runbookVersionId: runbookVersion.id, taskId: id })
              }
            } as MenuListItemProps),
          canCreateTaskFromPredecessor &&
            ({
              label: t('actions.addTaskAfter'),
              a11yTitle: t('actions.addTaskAfter'),
              icon: 'add',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                openTaskCreateForm({ predecessor: id })
              }
            } as MenuListItemProps),
          canCreateLinkedTask &&
            ({
              label: t('actions.addLinkedTask'),
              a11yTitle: t('actions.addLinkedTask'),
              icon: 'runbook',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                openModal({ id, type: 'linked-runbook-add' })
              }
            } as MenuListItemProps),
          canCreateSnippetTask &&
            ({
              label: t('actions.addSnippet'),
              a11yTitle: t('actions.addSnippet'),
              icon: 'snippet',
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                openModal({ id, type: 'snippet-add' })
              }
            } as MenuListItemProps),
          {
            label: t('actions.showCriticalPath'),
            a11yTitle: t('actions.showCriticalPath'),
            icon: 'critical-path',
            disabled: !predecessorIds.length, // Note: disabling instead of omitting so no chance of completely empty menu
            onClick: e => {
              e.syntheticEvent.stopPropagation()
              e.syntheticEvent.preventDefault()
              set(filterSelector({ attribute: 'critical_to_here' }), internalId)
            }
          } as MenuListItemProps,
          {
            label: t('actions.showAncestors'),
            a11yTitle: t('actions.showAncestors'),
            icon: 'predecessors',
            disabled: !predecessorIds.length,
            onClick: e => {
              e.syntheticEvent.stopPropagation()
              e.syntheticEvent.preventDefault()
              set(filterSelector({ attribute: 'predecessors_to_here' }), internalId)
            }
          } as MenuListItemProps,
          canDeleteTask &&
            ({
              label: t('actions.delete'),
              a11yTitle: t('actions.delete'),
              icon: 'delete',
              destructive: true,
              onClick: e => {
                e.syntheticEvent.stopPropagation()
                e.syntheticEvent.preventDefault()
                openModal({ id: [id], type: 'tasks-delete' })
              }
            } as MenuListItemProps)
        ].filter(Boolean) as MenuListItemProps[]

        return menuItems
      },
    [
      canAddSnippet,
      copyIdsAdd,
      getCanActionTask,
      getCanCreateTask,
      getCanDeleteTask,
      getCanEditRunbook,
      integrationRequest,
      notify,
      openModal,
      openTaskCreateForm,
      setIntegrationRequest,
      t
    ]
  )
}

const useBuildTaskDependencyMenuItems = () => {
  const toggleTaskEdit = useToggleRightPanel('task-edit')

  return useRecoilCallback(
    ({ snapshot }) =>
      async ({ task, type }: { task: TaskListTask; type: DependencyType }) => {
        const taskLookup = await snapshot.getPromise(taskListLookupState)
        const taskTypeLookup = await snapshot.getPromise(accountTaskTypeLookup)
        const streamLookup = await snapshot.getPromise(streamsLookupState)

        const dependencyIds = type === 'predecessors' ? task.predecessor_ids : task.successor_ids
        const tasks = dependencyIds.map(id => taskLookup[id])

        const menuItems = tasks
          .sort((a, b) => {
            return a.internal_id - b.internal_id
          })
          .map(task => {
            const { internal_id: internalId, name, id } = task
            const taskType = taskTypeLookup[task.task_type_id]

            const iconProps = {
              color: streamLookup[task.stream_id].color,
              icon: taskTypeIcon({
                icon: taskType.icon,
                stage: task.stage,
                isTemplate: task.linked_resource?.is_template
              }),
              inProgress: task.stage === 'in-progress',
              isOpaque: task.stage === 'complete',
              stageIcon: stageIconName({
                completionType: task.completion_type,
                stage: task.stage,
                startFixed: task.start_fixed
              })
            }

            const item = {
              icon: <TaskItemIcon iconSize="xsmall" {...iconProps} />,
              label: `#${internalId} ${name}`,
              onClick: () => toggleTaskEdit({ taskId: id })
            }
            return item
          })
        return menuItems
      },
    [toggleTaskEdit]
  )
}

/* -------------------------------------------------------------------------- */
/*                                    Modal                                   */
/* -------------------------------------------------------------------------- */

export const useModalValue = () => useRecoilValue(runbookViewStateModal_INTERNAL)

export const useModalValueCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        return await snapshot.getPromise(runbookViewStateModal_INTERNAL)
      },
    []
  )

export const useModalClose = () =>
  useRecoilCallback(
    ({ set }) =>
      () =>
        set(runbookViewStateModal_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.active = undefined
            draft.history = []
          })
        ),
    []
  )

export const useModalOpen = () =>
  useRecoilCallback(
    ({ set }) =>
      (modal: ModalActiveType) =>
        set(runbookViewStateModal_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.active = modal
            if (modal.type === 'tasks-csv-import') {
              // @ts-ignore
              draft.active.data = { idle: true, success: false }
            }
          })
        ),
    []
  )

export const useModalContinue = () =>
  useRecoilCallback(
    ({ set }) =>
      (nextModal: ModalActiveType, previousModal: ModalActiveType & { context?: object }) =>
        set(runbookViewStateModal_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.active = nextModal
            draft.history.push(previousModal)
          })
        ),
    []
  )

export const useModalUpdate = () =>
  useRecoilCallback(
    ({ set }) =>
      async (data?: object) =>
        set(runbookViewStateModal_INTERNAL, previousState =>
          produce(previousState, draft => {
            // Safety check if a WS message is processed by wrong browser session
            if (draft && draft.active) {
              // @ts-ignore
              draft.active.data = data
            }
          })
        ),
    []
  )

/* -------------------------------------------------------------------------- */
/*                                  Copy IDs                                  */
/* -------------------------------------------------------------------------- */

export const useCopyIdsAdd = () =>
  useRecoilCallback(
    ({ set }) =>
      (ids: number[]) => {
        set(runbookViewStateCopyIds_INTERNAL, ids)
      },
    []
  )

export const useCopyIdsRemove = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(runbookViewStateCopyIds_INTERNAL, [])
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                            New Comments Count                              */
/* -------------------------------------------------------------------------- */

export const useNewCommentsCountValue = () => useRecoilValue(runbookViewStateNewCommentsCount_INTERNAL)

export const useNewCommentsCountReset = () =>
  useRecoilCallback(
    ({ set }) =>
      async () => {
        set(runbookViewStateNewCommentsCount_INTERNAL, 0)
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                                 Timezone                                   */
/* -------------------------------------------------------------------------- */

export const useSelectedTimezoneValue = () => useRecoilValue(runbookViewStateSelectedTimezone_INTERNAL)

export const useActiveTimezoneValue = () => {
  const selectedTimezone = useRecoilValue(runbookViewStateSelectedTimezone_INTERNAL)
  const { timezone: defaultTimezone } = useRecoilValue(runbookState)
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const activeTimezone = selectedTimezone ?? defaultTimezone ?? null

  return activeTimezone !== localTimezone ? activeTimezone : null
}

export const useSetSelectedTimezone = () =>
  useRecoilCallback(
    ({ set }) =>
      (selectedTimezone: string | null) =>
        set(runbookViewStateSelectedTimezone_INTERNAL, selectedTimezone),
    []
  )

/* -------------------------------------------------------------------------- */
/*                                Selected Ids                                */
/* -------------------------------------------------------------------------- */

export const useSelectedIdsValue = () => useRecoilValue(runbookViewStateSelectedIds_INTERNAL)

export const useSelectedIdsValueCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        snapshot.getPromise(runbookViewStateSelectedIds_INTERNAL),
    []
  )

export const useCopyIdsValue = () => useRecoilValue(runbookViewStateCopyIds_INTERNAL)

export const useCopyIdsValueCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        snapshot.getPromise(runbookViewStateCopyIds_INTERNAL),
    []
  )

export const useSelectedIdAdd = () =>
  useRecoilCallback(
    ({ set }) =>
      (id: number) => {
        set(runbookViewStateSelectedIds_INTERNAL, previousState =>
          produce(previousState, draft => {
            draft.push(id)
          })
        )
      },
    []
  )

export const useSelectedIdRemove = () =>
  useRecoilCallback(
    ({ set }) =>
      (id: number) => {
        set(runbookViewStateSelectedIds_INTERNAL, previousState =>
          produce(previousState, draft => {
            const index = draft.indexOf(id)
            if (index !== -1) draft.splice(index, 1)
          })
        )
      },
    []
  )

export const useSelectedIdBulkRemove = () =>
  useRecoilCallback(
    ({ set }) =>
      (ids: number[]) => {
        set(runbookViewStateSelectedIds_INTERNAL, prev =>
          produce(prev, draft => {
            pullAllWith(draft, ids)
          })
        )
      },
    []
  )

export const useSelectedIdsOverwrite = () =>
  useRecoilCallback(
    ({ set }) =>
      (ids: number[]) => {
        set(runbookViewStateSelectedIds_INTERNAL, ids)
      },
    []
  )

export const useSelectedIdToggle = () => {
  const selectedIdRemove = useSelectedIdRemove()
  const selectedIdAdd = useSelectedIdAdd()
  const selectedIdsOverwrite = useSelectedIdsOverwrite()

  return useRecoilCallback(
    ({ snapshot }) =>
      async (id: number, shiftKey: boolean) => {
        const selectedIds = await snapshot.getPromise(runbookViewStateSelectedIds_INTERNAL)
        if (shiftKey && selectedIds.length === 1 && selectedIds[0] !== id) {
          // If holding shift and clicking a different task to the already selected one, select all tasks between the 2
          const taskIds = await snapshot.getPromise(filteredTaskListIdsState)
          const indexOfSelected = taskIds.indexOf(selectedIds[0])
          const indexOfClicked = taskIds.indexOf(id)
          const startIndex = Math.min(indexOfSelected, indexOfClicked)
          const endIndex = Math.max(indexOfSelected, indexOfClicked)
          const newSelectedIds = taskIds.slice(startIndex, endIndex + 1)
          selectedIdsOverwrite(newSelectedIds)
        } else {
          selectedIds.includes(id) ? selectedIdRemove(id) : selectedIdAdd(id)
        }
      },
    [selectedIdAdd, selectedIdRemove, selectedIdsOverwrite]
  )
}

export const useSelectedIdsSelectAll = () => {
  const selectedIdsOverwrite = useSelectedIdsOverwrite()

  return useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const taskIds = await snapshot.getPromise(filteredTaskListIdsState)
        selectedIdsOverwrite(taskIds)
      },
    [selectedIdsOverwrite]
  )
}

export const useSelectedIdsToggleAll = () => {
  const selectedIdsRemoveAll = useSelectedIdsRemoveAll()
  const selectedIdsSelectAll = useSelectedIdsSelectAll()

  return useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const selectedIds = await snapshot.getPromise(runbookViewStateSelectedIds_INTERNAL)
        selectedIds.length ? selectedIdsRemoveAll() : selectedIdsSelectAll()
      },
    [selectedIdsRemoveAll, selectedIdsSelectAll]
  )
}

export const useSelectedIdsRemoveAll = () => {
  const selectedIdsOverwrite = useSelectedIdsOverwrite()
  return useCallback(() => selectedIdsOverwrite([]), [selectedIdsOverwrite])
}

/* -------------------------------------------------------------------------- */
/*                               Selected Edges                               */
/* -------------------------------------------------------------------------- */

export const useSelectedEdgesValue = () => useRecoilValue(runbookViewStateSelectedEdges_INTERNAL)

export const useSelectedEdgesValueCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        snapshot.getPromise(runbookViewStateSelectedEdges_INTERNAL),
    []
  )

export const useSelectedEdgesSet = () =>
  useRecoilCallback(
    ({ set }) =>
      (edges: Edge[]) => {
        set(
          runbookViewStateSelectedEdges_INTERNAL,
          edges?.map(edge => `${edge.parent_id}:${edge.task_id}`)
        )
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                               Highlight Mode                               */
/* -------------------------------------------------------------------------- */

export const useHighlightMode = () => useRecoilValue(runbookViewStateHighlightMode_INTERNAL)

export const useHighlightModeCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(runbookViewStateHighlightMode_INTERNAL),
    []
  )

export const useSetHighlightMode = () =>
  useRecoilCallback(
    ({ set }) =>
      (highlight: boolean) =>
        set(runbookViewStateHighlightMode_INTERNAL, highlight),
    []
  )

/* -------------------------------------------------------------------------- */
/*                             Integration Request                            */
/* -------------------------------------------------------------------------- */

export const useIntegrationRequestValue = () => useRecoilValue(runbookViewStateIntegrationRequest_INTERNAL)

export const useIntegrationRequestValueCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        snapshot.getPromise(runbookViewStateIntegrationRequest_INTERNAL),
    []
  )

export const useIntegrationRequestAdd = () =>
  useRecoilCallback(
    ({ set }) =>
      (request: IntegrationRequest) => {
        set(runbookViewStateIntegrationRequest_INTERNAL, previousState =>
          produce(previousState, draft => {
            const { taskId, type } = request
            draft[taskId] = type
          })
        )
      },
    []
  )

export const useIntegrationRequestRemove = () =>
  useRecoilCallback(
    ({ set }) =>
      (taskId: number) => {
        set(runbookViewStateIntegrationRequest_INTERNAL, previousState =>
          produce(previousState, draft => {
            delete draft[taskId]
          })
        )
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                                 Permissions                                */
/* -------------------------------------------------------------------------- */

export const useEditRunbookPermissions = () => {
  const versionIsEditable = useRecoilValue(isVersionEditable)
  const hasUpdatePermissions = useActiveRunbookCan('update')
  const hasPermittedStreams = !!useRecoilValue(streamsPermittedState).length

  if (!hasUpdatePermissions && !hasPermittedStreams)
    return { can: false, error: !hasUpdatePermissions ? 'NO_PERMISSION' : 'NO_PERMITTED_STREAMS' }
  if (!versionIsEditable) return { can: false, error: 'VERSION_NOT_EDITABLE' }

  return { can: true }
}

export const useEditRunbookPermissionsCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const versionIsEditable = await snapshot.getPromise(isVersionEditable)
        const hasUpdatePermissions = await snapshot.getPromise(runbookPermission({ attribute: 'update' }))
        const hasPermittedStreams = !!(await snapshot.getPromise(streamsPermittedState)).length

        if (!hasUpdatePermissions && !hasPermittedStreams)
          return { can: false, error: !hasUpdatePermissions ? 'NO_PERMISSION' : 'NO_PERMITTED_STREAMS' }
        if (!versionIsEditable) return { can: false, error: 'VERSION_NOT_EDITABLE' }

        return { can: true }
      },
    []
  )

export const useCanCreateRootTask = () => {
  const newTaskStreamId = useRecoilValue(newTaskStreamState({}))
  const versionIsEditable = useRecoilValue(isVersionEditable)

  if (!versionIsEditable) return { can: false, error: 'VERSION_NOT_EDITABLE' }
  if (!newTaskStreamId) return { can: false, error: 'NO_PERMITTED_STREAMS' }
  return { can: true }
}

export const useCanCreateRootTaskCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const newTaskStreamId = !!(await snapshot.getPromise(newTaskStreamState({})))
        const versionIsEditable = await await snapshot.getPromise(isVersionEditable)

        if (!versionIsEditable) return { can: false, error: 'VERSION_NOT_EDITABLE' }
        if (!newTaskStreamId) return { can: false, error: 'NO_PERMITTED_STREAMS' }
        return { can: true }
      },
    []
  )

export const useCanInitiateBulkEditActions = () => {
  const selectedIds = useSelectedIdsValue()
  const editRunbookPermissions = useEditRunbookPermissions()

  if (!editRunbookPermissions.can) return editRunbookPermissions
  if (selectedIds.length === 0) return { can: false, error: 'NO_SELECTED_IDS' }
  return { can: true }
}

// Note: Just used for skip icon currently. No longer admins only, any user can see it in certain runbook state
// This is because users assigned to tasks can now skip their own tasks
export const useCanInitiateBulkProgressionActions = () => {
  const selectedIds = useSelectedIdsValue()
  const stage = useRecoilValue(runbookVersionStageState)

  if (stage !== 'active') return { can: false, error: 'VERSION_ACTIVE' }
  if (selectedIds.length === 0) return { can: false, error: 'NO_SELECTED_IDS' }
  return { can: true }
}

export const useCanInitiateBulkEditActionsCallback = () => {
  const selectedIdsCallback = useSelectedIdsValueCallback()
  const getEditRunbookPermissions = useEditRunbookPermissionsCallback()

  return useRecoilCallback(
    () => async () => {
      const editRunbookPermissions = await getEditRunbookPermissions()

      if (!editRunbookPermissions.can) return editRunbookPermissions

      const selectedIds = await selectedIdsCallback()
      if (selectedIds.length === 0) return { can: false, error: 'NO_SELECTED_IDS' }
      return { can: true }
    },
    [selectedIdsCallback, getEditRunbookPermissions]
  )
}

export const useCanInitiateBulkProgressionActionsCallback = () => {
  const selectedIdsCallback = useSelectedIdsValueCallback()

  return useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const selectedIds = await selectedIdsCallback()

        const stage = await snapshot.getPromise(runbookVersionStageState)

        if (stage !== 'active') return { can: false, error: 'VERSION_ACTIVE' }
        if (selectedIds.length === 0) return { can: false, error: 'NO_SELECTED_IDS' }
        return { can: true }
      },
    [selectedIdsCallback]
  )
}

export const useCanShowPirLink = () => {
  const { stage } = useGetRunbookVersion()
  const pirDashboard = useGetDashboardBy({ key: 'pir' })
  const run = useGetRun()

  if (stage !== 'complete') return { can: false, error: 'STAGE_NOT_CORRECT' }
  if (!pirDashboard) return { can: false, error: 'NO_DASHBOARD' }
  if (!run?.run_type || run.run_type !== 'live') return { can: false, error: 'RUN_TYPE_NOT_CORRECT' }
  return { can: true }
}

export const useCanShowPirLinkCallback = () => {
  const getRunbookVersion = useGetRunbookVersionCallback()
  const getDashboardBy = useGetDashboardByCallback()
  const getRun = useGetRunCallback()

  return useRecoilCallback(
    () => async () => {
      const { stage } = await getRunbookVersion()
      const pirDashboard = await getDashboardBy({ key: 'pir' })
      const run = await getRun()

      if (stage !== 'complete') return { can: false, error: 'STAGE_NOT_CORRECT' }
      if (!pirDashboard) return { can: false, error: 'NO_DASHBOARD' }
      if (!run?.run_type || run.run_type !== 'live') return { can: false, error: 'RUN_TYPE_NOT_CORRECT' }
      return { can: true }
    },
    [getDashboardBy, getRun, getRunbookVersion]
  )
}

/* -------------------------------------------------------------------------- */
/*                                 Task Create                                */
/* -------------------------------------------------------------------------- */

export const useTaskCreateValue = () => useRecoilValue(runbookViewStateTaskCreate_INTERNAL)

export const useTaskCreateValueCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        snapshot.getPromise(runbookViewStateTaskCreate_INTERNAL),
    []
  )

export const useTaskCreateToggleForm = () =>
  useRecoilCallback(
    ({ set }) =>
      async ({ predecessor }) => {
        set(runbookViewStateTaskCreate_INTERNAL, prev =>
          produce(prev, draft => {
            if (draft.predecessor === predecessor) {
              draft.predecessor = undefined
              draft.name = undefined
            } else if (predecessor === 0) {
              draft.predecessor = predecessor
              draft.name = undefined
            } else {
              draft.predecessor = predecessor
            }
          })
        )
      },
    []
  )

export const useTaskCreateOpenForm = () => {
  const setViewState = useSetRecoilState(runbookViewStateTaskCreate_INTERNAL)

  return useCallback(
    ({ predecessor }: { predecessor?: number }) =>
      setViewState(prev =>
        produce(prev, draft => {
          draft.predecessor = predecessor
          draft.name = undefined
        })
      ),
    [setViewState]
  )
}

export const useTaskCreateCloseForm = () => {
  const setViewState = useSetRecoilState(runbookViewStateTaskCreate_INTERNAL)

  return useCallback(
    () =>
      setViewState(prev =>
        produce(prev, draft => {
          draft.predecessor = undefined
          draft.name = undefined
        })
      ),
    [setViewState]
  )
}

export const useTaskCreateSetName = () => {
  const setViewState = useSetRecoilState(runbookViewStateTaskCreate_INTERNAL)

  return useCallback(
    (name: string | undefined) =>
      setViewState(prev =>
        produce(prev, draft => {
          draft.name = name
        })
      ),
    [setViewState]
  )
}

/* -------------------------------------------------------------------------- */
/*                           Task Sort Menu                                   */
/* -------------------------------------------------------------------------- */

export const useSortValue = () => useRecoilValue(runbookViewStateSort_INTERNAL)
export const useSetSort = () => useSetRecoilState(runbookViewStateSort_INTERNAL)

/* -------------------------------------------------------------------------- */
/*                           Node Map Version Flags                           */
/* -------------------------------------------------------------------------- */

export const useNodeMapVersion = () => {
  return useRecoilValue(runbookViewStateNodeMap_INTERNAL)
}

export const useNodeMapVersionCallback = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        return await snapshot.getPromise(runbookViewStateNodeMap_INTERNAL)
      },
    []
  )

export const useNodeMapIncrementGraphVersion = () =>
  useRecoilCallback(
    ({ set }) =>
      async () => {
        set(runbookViewStateNodeMap_INTERNAL, prev =>
          produce(prev, draft => {
            if (draft) {
              draft.graphVersionFlag = prev.graphVersionFlag + 1
            }
          })
        )
      },
    []
  )

export const useNodeMapIncrementDataVersion = () =>
  useRecoilCallback(
    ({ set }) =>
      async () => {
        set(runbookViewStateNodeMap_INTERNAL, prev =>
          produce(prev, draft => {
            if (draft) {
              draft.dataVersionFlag = prev.dataVersionFlag + 1
            }
          })
        )
      },
    []
  )

export const useNodeMapResetVersionFlags = () =>
  useRecoilCallback(
    ({ set }) =>
      async () => {
        set(runbookViewStateNodeMap_INTERNAL, prev =>
          produce(prev, draft => {
            if (draft) {
              draft.graphVersionFlag = 0
              draft.dataVersionFlag = 0
            }
          })
        )
      },
    []
  )

/* -------------------------------------------------------------------------- */
/*                           Notifications                                    */
/* -------------------------------------------------------------------------- */

export const useRunbookNotifications = () => {
  const notify = useNotify()

  const success = useRecoilCallback(
    ({ snapshot }) =>
      (message: string, args?: NotifyOptions) => {
        const notifications = snapshot.getLoadable(runbookViewStateNotifications_INTERNAL).valueMaybe()
        if (notifications === 'off') return
        notify.success(message, args)
      },
    [notify]
  )

  const error = useRecoilCallback(
    ({ snapshot }) =>
      (message: string, args?: NotifyOptions) => {
        const notifications = snapshot.getLoadable(runbookViewStateNotifications_INTERNAL).valueMaybe()
        if (notifications === 'off') return
        notify.error(message, args)
      },
    [notify]
  )

  const warning = useRecoilCallback(
    ({ snapshot }) =>
      (message: string, args: RequiredTitleNotifyOptions) => {
        const notifications = snapshot.getLoadable(runbookViewStateNotifications_INTERNAL).valueMaybe()
        if (notifications === 'off') return
        notify.warning(message, args)
      },
    [notify]
  )

  const info = useRecoilCallback(
    ({ snapshot }) =>
      (message: string, args: RequiredTitleNotifyOptions) => {
        const notifications = snapshot.getLoadable(runbookViewStateNotifications_INTERNAL).valueMaybe()
        if (notifications === 'off') return
        notify.info(message, args)
      },
    [notify]
  )

  return {
    success,
    error,
    warning,
    info
  }
}
