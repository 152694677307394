import { keyBy, mapValues } from 'lodash'
import { atom, AtomEffect, DefaultValue, selector, selectorFamily } from 'recoil'

import { accountMetaState } from '../account/account'
import { appliedFilterState, nowDateAtom } from 'main/recoil/shared/filters'
import { applyFilterPipeline } from 'main/services/tasks/filtering/filter-map'
import { buildFilterContext, TaskFilterContext } from 'main/services/tasks/filtering/filter-context'
import { getRunbookId, getRunbookVersionId } from 'main/recoil/shared/nav-utils'
import { getTasks, TaskListResponseType } from 'main/services/queries/use-tasks'
import { streamsInternalIdLookupState } from '../runbook-version/streams'
import { runbookVersionMetaState, runbookVersionResponseState_INTERNAL } from '../runbook-version/runbook-version'
import { runbookComponentsInternalIdLookup } from '../runbook-version/runbook-components'
import { sort } from 'main/services/tasks/sort'
import { FieldValue, TaskListTask } from 'main/services/queries/types'
import { currentUserIdState } from 'main/recoil/current-user'
import { RunbookFilterType } from 'main/services/tasks/filtering'
import { teamsStateLookup } from '../runbook-version/teams'
import { taskListResponseDataHelpers } from 'main/data-access/hooks/recoil'
import { runbookRunbookTypeState } from '../runbook/runbook'
import { runbookViewStateSort_INTERNAL } from '../view/view'

export type TaskListResponseAndComputedPropertiesState = TaskListResponseType & {
  computed: {
    taskIds: number[]
    taskIdToExtendedFieldValueLookup: Record<number, FieldValue[]>
  }
}

const refreshTasksEffect: AtomEffect<TaskListResponseAndComputedPropertiesState> = ({ setSelf, getPromise }) => {
  const handleRefresh = async (event: any) => {
    if (event.detail.type === 'tasks') {
      const runbookId = getRunbookId()
      const runbookVersionId = getRunbookVersionId()

      if (runbookId && runbookVersionId) {
        const data = await getTasks(runbookId, runbookVersionId)
        const runbookComponents = (await getPromise(runbookVersionResponseState_INTERNAL)).meta.runbook_components
        setSelf(taskListResponseDataHelpers.extend(data, { runbookComponents }))
      } else {
        return new DefaultValue()
      }
    }
  }

  window.addEventListener('refresh-data-store', handleRefresh as any)

  return () => {
    window.removeEventListener('refresh-data-store', handleRefresh as any)
  }
}

// this is what is updated automatically with the url sync and provides the initial value
// for all tasks to build the lookup
export const taskListResponseState_INTERNAL = atom<TaskListResponseAndComputedPropertiesState>({
  key: 'tasks:response',
  // confirm needed and doc why (add properties to objects during crutical path evaluation)
  dangerouslyAllowMutability: true,
  effects: [refreshTasksEffect]
})

export const taskListState = selector({
  key: 'tasks:tasks',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  },
  get: ({ get }) => {
    const { tasks } = get(taskListResponseState_INTERNAL)
    return tasks
  }
})

export const taskListMetaState = selector({
  key: 'tasks:meta',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  },
  get: ({ get }) => {
    const { meta } = get(taskListResponseState_INTERNAL)
    return meta
  }
})

export const taskListLookupState = selector<Record<string, TaskListTask>>({
  key: 'tasks:lookup',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  },
  get: ({ get }) => {
    const tasksResponse = get(taskListResponseState_INTERNAL)
    const { tasks } = tasksResponse

    return keyBy(tasks, 'id')
  }
})

export const taskListInternalIdLookupState = selector<Record<string, TaskListTask>>({
  key: 'tasks:internal-id:lookup',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  },
  get: ({ get }) => {
    const { tasks } = get(taskListResponseState_INTERNAL)

    return keyBy(tasks, 'internal_id')
  }
})

export const taskIdsState = selector({
  key: 'tasks:ids',
  get: ({ get }) => {
    const { computed } = get(taskListResponseState_INTERNAL)
    return computed.taskIds
  }
})

// TODO: needs a context selector that conditionally builds a context object based on the applied filters.
// Needs to be updated and replaced with fully conditionally logical context to optimize filter matching as part of
// https://cutover.atlassian.net/browse/CFE-1138. Also consolidate the filter context creating between here and the
// buildFilterContext function in main/services/tasks/filtering/filter-map.ts
const taskListFilterContext = selectorFamily<TaskFilterContext, RunbookFilterType>({
  key: 'tasks:filter-context',
  get:
    filters =>
    ({ get }) => {
      // TODO: can we get this from the tasks list state?
      const { tasks } = get(taskListResponseState_INTERNAL)

      const { task_types, custom_fields } = get(accountMetaState)
      const { runbook_teams, users } = get(runbookVersionMetaState)
      const currentUserId = get(currentUserIdState)
      const streamInternalIdLookup = get(streamsInternalIdLookupState)
      const now = get(nowDateAtom)
      const fieldValuesLookup = get(taskListCustomFieldsValuesLookup)
      const taskLookup = get(taskListLookupState)
      const runbookComponentInternalIdLookup = get(runbookComponentsInternalIdLookup)

      return buildFilterContext(filters, {
        now,
        field_values_lookup: fieldValuesLookup,
        tasks,
        task_types,
        custom_fields,
        // @ts-ignore shouldn't ever be undefined...
        current_user: currentUserId,
        runbook_teams: runbook_teams,
        runbook_users: users, // currently not used, but should this be?
        streamInternalIdLookup,
        taskLookup,
        runbookComponentInternalIdLookup
      })
    }
})

export type FieldValuesByTaskAndCustomFieldIdLookup = {
  [task_id: number]: {
    [custom_field_id: number]: {
      value: string | null
      field_option_id: number | null
    }[]
  }
}

const taskListCustomFieldsValuesLookup = selector({
  key: 'tasks:custom-fields:field-values:lookup',
  get: ({ get }) => {
    const taskToFieldValueRecord = get(taskIdToExtendedFieldValuesRecord)
    const fieldValuesLookup: FieldValuesByTaskAndCustomFieldIdLookup = {}

    Object.keys(taskToFieldValueRecord).forEach(taskId => {
      const id = taskId as unknown as number
      const fieldValues = taskToFieldValueRecord[id]
      fieldValuesLookup[id] = {}

      fieldValues.forEach(fieldValue => {
        const { custom_field_id, value, field_option_id } = fieldValue
        const currentValue = fieldValuesLookup[id][custom_field_id] ?? []
        fieldValuesLookup[id][custom_field_id] = [...currentValue, { value, field_option_id }]
      })
    })

    return fieldValuesLookup
  }
})

export const filteredTaskListDataState = selector<[TaskListTask[], TaskFilterContext]>({
  key: 'tasks:filter-data',
  get: ({ get }) => {
    const filters = get(appliedFilterState)
    const filterContext = get(taskListFilterContext(filters))
    const { tasks } = get(taskListResponseState_INTERNAL)

    const filteredTasks = applyFilterPipeline([...tasks], filters, filterContext)
    const isIncident = get(runbookRunbookTypeState).incident

    const sortState = get(runbookViewStateSort_INTERNAL)
    const sortValue = isIncident ? sortState : { option: 'start_latest_planned', direction: 'asc' }
    const sortedTasks = sort(filteredTasks, sortValue.option, sortValue.direction)

    return [sortedTasks, filterContext]
  }
})

export const filteredTaskListIdsState = selector<number[]>({
  key: 'tasks:filter-ids',
  get: ({ get }) => {
    const [sortedTasks] = get(filteredTaskListDataState)

    return sortedTasks.map(t => t.id)
  }
})

export const filteredTasksState = selector({
  key: 'tasks:filtered',
  get: ({ get }) => {
    const filters = get(appliedFilterState)
    const hasFilters = !!Object.keys(filters ?? {}).length

    if (!hasFilters) {
      return get(taskListResponseState_INTERNAL).tasks
    }

    const [filteredTasks] = get(filteredTaskListDataState)
    return filteredTasks
  }
})

export const taskIdToExtendedFieldValuesRecord = selector<Record<number, FieldValue[]>>({
  key: 'tasks:extended-field-values',
  get: ({ get }) => {
    return get(taskListResponseState_INTERNAL).computed.taskIdToExtendedFieldValueLookup
  }
})

export const taskListFilteredCountState = selector<number>({
  key: 'tasks:count',
  get: ({ get }) => {
    const filteredIds = get(filteredTaskListIdsState)
    return filteredIds.length
  }
})

export const taskListTaskState = selectorFamily<TaskListTask, number>({
  key: 'task:id',
  get:
    id =>
    ({ get }) => {
      const tasks = get(taskListLookupState)
      return tasks[id]
    }
})

export const teamIdToTaskRecord = selector<Record<number, TaskListTask[]>>({
  key: 'teams:tasks:count',
  get: ({ get }) => {
    const teamsLookup = get(teamsStateLookup)
    const filteredTasks = get(filteredTasksState)

    return mapValues(teamsLookup, team => {
      return filteredTasks.filter(task => task.runbook_team_ids?.includes(team.id))
    })
  }
})
